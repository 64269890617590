/* eslint-disable react/prop-types */
// source: https://github.com/segmentio/evergreen/issues/859
import { useMediaQuery } from 'react-responsive'

export const Device = ({ children, desktop, mobile, tablet, not }) => {
  /*
        ======================================
          This component renders children
          based on size of the screen. The usage
          is extremely simple but the component
          definition looks kind of ugly.
        ======================================
      */
  let condition = false
  const validate = (variable, name) => {
    /*
              ======================================
                This function basically takes in
                the variable and the name of the variable
                (should lie as a key in 'range' variable below).
                And then implemnts all the logic required to set
                the final condition.
              ======================================
            */
    const range = {
      mobile: {
        S: { maxWidth: 320 },
        M: { minWidth: 321, maxWidth: 375 },
        L: { minWidth: 376, maxWidth: 425 },
        XL: { minWidth: 426, maxWidth: 767 },
        generic: { maxWidth: 767 }
      },
      tablet: {
        generic: { minWidth: 768, maxWidth: 991 }
      },
      desktop: {
        S: { minWidth: 992, maxWidth: 1024 },
        M: { minWidth: 1024, maxWidth: 1360 },
        L: { minWidth: 1360, maxWidth: 2000 },
        XL: { minWidth: 2000 },
        generic: { minWidth: 992 }
      }
    }
    /*
              ======================================
                If that variable is a string, then
                each word in that string must be a valid
                size. And if that's true then we include the
                contribution of condition corresponding to that
                size in the final result. If not, we simply ignore it.
              ======================================
            */
    if (variable) {
      if (typeof variable === 'string' || variable instanceof String) {
        variable.split(' ').forEach(i => {
          const size = range[name] ? (range[name][i] ? useMediaQuery(range[name][i]) : undefined) : undefined
          if (typeof size !== 'undefined') condition = condition || size
        })
      } else if (typeof variable === 'boolean') {
        if (range[name]) condition = condition || useMediaQuery(range[name].generic)
      }
    }
  }
  /*
        ======================================
          Finally, we run the checks and also
          if user doesn't provide any device,
          by default its assumed that the children
          should be rendered on all screens.
        ======================================
      */
  validate(mobile, 'mobile')
  validate(tablet, 'tablet')
  validate(desktop, 'desktop')
  if (!desktop && !mobile && !tablet) condition = true
  condition = not ? !condition : condition
  return condition ? children : null
}

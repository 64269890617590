import React, { useEffect, useState } from 'react'
import { Device } from '../../components/responsiveness'
import { Pane, majorScale, Heading, Button, Paragraph, minorScale } from 'evergreen-ui'
import ScaleLoader from 'react-spinners/BarLoader' // Blocked on local host
import './home.css'
export default function Home () {
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    // render a script
    const script = document.createElement('script')
    script.src = 'https://assets.calendly.com/assets/external/widget.js'
    script.onload = function () {
      setLoaded(true)
    }
    document.getElementsByTagName('head')[0].appendChild(script)
  }, [])
  if (!loaded) {
    return (
    <Pane background="dark" width="100vw" height="100vh" display="flex" justifyContent="center">
      <Pane alignSelf="center" justifyContent="center" display="flex" flexDirection="column">
        <ScaleLoader color="white" size={150} />
        <Paragraph textAlign="center" margin={majorScale(2)}>Loading ...</Paragraph>
      </Pane>
    </Pane>
    )
  }
  return (
    <>
      <Device desktop>
        <Pane display="flex" flexDirection="column" paddingX={majorScale(32)} paddingY={majorScale(16)} background="gray50" height="90vh">
          <Heading size={700} color="#101840" alignSelf="center" marginBottom={majorScale(7)}>Hey, what do you want to talk about?</Heading>
          <Button appearance="secondary" background="white" borderColor="#D8DAE5" marginBottom={majorScale(4)} size="large" width="fit-content" alignSelf="center" onClick={scheduleMixingCall}>How to mix the vibe I hear in my head.</Button>
          <Button appearance="primary" size="large" width="fit-content" alignSelf="center" onClick={scheduleAudienceGrowthCall}>How to grow my audience online.</Button>
        </Pane>
        <Pane display="flex" flexDirection="column" background="gray900" paddingX={majorScale(32)} paddingY={majorScale(8)}>
          <Paragraph color="#696F8C" textAlign="center">Melodicjeanious Academy © 2022, All rights reserved.</Paragraph>
        </Pane>
      </Device>
      <Device mobile>
        <Pane display="flex" flexDirection="column" paddingX={minorScale(4)} paddingY={minorScale(64)} background="gray50" height="90vh">
          <Heading size={700} color="#101840" alignSelf="center" marginBottom={minorScale(7)}>Hey, what do you want to talk about?</Heading>
          <Button appearance="secondary" background="white" borderColor="#D8DAE5" marginBottom={minorScale(4)} size="large" width="fit-content" alignSelf="center" onClick={scheduleMixingCall}>How to mix the vibe I hear in my head.</Button>
          <Button appearance="primary" size="large" width="fit-content" alignSelf="center" onClick={scheduleAudienceGrowthCall}>How to grow my audience online.</Button>
        </Pane>
        <Pane display="flex" flexDirection="column" background="gray900" paddingX={minorScale(4)} paddingY={minorScale(8)}>
          <Paragraph color="#696F8C" textAlign="center">Melodicjeanious Academy © 2022, All rights reserved.</Paragraph>
        </Pane>
      </Device>
      <Device tablet>
        <Pane display="flex" flexDirection="column" paddingX={majorScale(32)} paddingY={majorScale(16)} background="gray50" height="93vh">
          <Heading size={700} color="#101840" alignSelf="center" textAlign="center" marginBottom={majorScale(7)}>Hey, what do you want to talk about?</Heading>
          <Button appearance="secondary" background="white" borderColor="#D8DAE5" marginBottom={majorScale(4)} size="large" width="fit-content" alignSelf="center" onClick={scheduleMixingCall}>How to mix the vibe I hear in my head.</Button>
          <Button appearance="primary" size="large" width="fit-content" alignSelf="center" onClick={scheduleAudienceGrowthCall}>How to grow my audience online.</Button>
        </Pane>
        <Pane display="flex" flexDirection="column" background="gray900" paddingX={majorScale(16)} paddingY={majorScale(4)}>
          <Paragraph color="#696F8C" textAlign="center">Melodicjeanious Academy © 2022, All rights reserved.</Paragraph>
        </Pane>
      </Device>
    </>
  )
}
function scheduleMixingCall () {
  return window.Calendly.initPopupWidget({ url: 'https://calendly.com/richiedagenius/melodicjeanious-academy' })
}
function scheduleAudienceGrowthCall () {
  return window.Calendly.initPopupWidget({ url: 'https://calendly.com/richiedagenius/melodicjeanious-consulting' })
}

/**
 * React Google Analytics Module
 * Class && Component written by Richard
 * @package react-ga
 * @author  Adam Lofting <adam@mozillafoundation.org>
 *          Atul Varma <atul@mozillafoundation.org>
 * @author  Richard Famoroti <richardfamoroti@gmail.com>
 */
import { useEffect, useCallback } from 'react'
import { useLocation } from 'react-router-dom'

class GA {
  constructor () {
    this.debug = false
    this.titleCase = true
    this.testMode = false
    this.alwaysSendToDefaultTracker = true
    this.redactEmail = true
    this.isNotBrowser = typeof window === 'undefined' || typeof document === 'undefined'
  }

  format = (s = '', titleCase, redactingEmail = true) => {
    let str = s || ''
    if (titleCase) {
      str = this.toTitleCase(s)
    }
    if (redactingEmail) {
      str = this.redactEmail(str)
    }
    return str
  }

  redactEmail = (string) => {
    const redacted = 'REDACTED (Potential Email Address)'
    if (this.mightBeEmail(string)) {
      this.warn('This arg looks like an email address, redacting.')
      return redacted
    }

    return string
  }

  mightBeEmail = (s) => typeof s === 'string' && s.indexOf('@') !== -1

  warn = (s) => console.warn('[Google-Analytics]', s)

  log = (s) => console.info('[Google-Analytics]', s)

  loadGA = (options) => {
    let gaAddress = 'https://www.google-analytics.com/analytics.js'
    if (options && options.gaAddress) {
      gaAddress = options.gaAddress
    } else if (options && options.debug) {
      gaAddress = 'https://www.google-analytics.com/analytics_debug.js'
    }
    const onerror = options && options.onerror;
    /* eslint-disable */
        (function (i, s, o, g, r, a, m) {
            i['GoogleAnalyticsObject'] = r;
            (i[r] =
                i[r] ||
                function () {
                    (i[r].q = i[r].q || []).push(arguments);
                }),
                (i[r].l = 1 * new Date());
            (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
            a.async = 1;
            a.src = g;
            a.onerror = onerror;
            m.parentNode.insertBefore(a, m);
        })(window, document, 'script', gaAddress, 'ga');
        /* eslint-enable */
  }

  removeLeadingSlash = (string) => (string.substring(0, 1) === '/' ? string.substring(1) : string)

  testModeAPI = () => {
    const gaCalls = []
    return {
      calls: gaCalls,
      ga: (...args) => {
        gaCalls.push([...args])
      },
      resetCalls: () => {
        gaCalls.length = 0
      }
    }
  }

  toTitleCase = (string) => this.trim(string).replace(
    /[A-Za-z0-9\u00C0-\u00FF]+[^\s-]*/g,
    (match, index, title) => {
      const smallWords = /^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|vs?\.?|via)$/i
      if (
        index > 0 &&
                index + match.length !== title.length &&
                match.search(smallWords) > -1 &&
                title.charAt(index - 2) !== ':' &&
                (title.charAt(index + match.length) !== '-' ||
                    title.charAt(index - 1) === '-') &&
                title.charAt(index - 1).search(/[^\s-]/) < 0
      ) {
        return match.toLowerCase()
      }

      if (match.substr(1).search(/[A-Z]|\../) > -1) {
        return match
      }

      return match.charAt(0).toUpperCase() + match.substr(1)
    }
  )

  trim = (s) => s && s.toString().replace(/^\s+|\s+$/g, '')

  // END OF UTILS, CLASS FUNCTIONS BELOW

  internalGa = (...args) => {
    if (this.testMode) return this.TestModeAPI.ga(...args)
    if (this.isNotBrowser) return false
    return !window.ga ? this.warn('GA.initialize must be called first or GoogleAnalytics should be loaded manually') : window.ga(...args)
  }

  _format = (s) => this.format(s, this.titleCase, this.redactEmail)

  gaCommand = (trackerNames, ...args) => {
    const command = args[0]
    if (typeof this.internalGa === 'function') {
      if (typeof command !== 'string') {
        this.warn('ga command must be a string')
        return
      }
      if (this.alwaysSendToDefaultTracker || !Array.isArray(trackerNames)) this.internalGa(...args)
      if (Array.isArray(trackerNames)) {
        trackerNames.forEach((name) => {
          this.internalGa(...[`${name}.${command}`].concat(args.slice(1)))
        })
      }
    }
  }

  _initialize = (gaTrackingID, options) => {
    if (!gaTrackingID) {
      this.warn('gaTrackingID is required in initialize()')
      return
    }
    if (options) {
      if (options.debug && options.debug === true) {
        this.debug = true
      }

      if (options.titleCase === false) {
        this.titleCase = false
      }

      if (options.redactEmail === false) {
        this.redactEmail = false
      }

      if (options.useExistingGa) {
        return
      }
    }

    if (options && options.gaOptions) {
      this.internalGa('create', gaTrackingID, options.gaOptions)
    } else {
      this.internalGa('create', gaTrackingID, 'auto')
    }
  }

  addTrackers = (configsOrTrackingId, options) => {
    if (Array.isArray(configsOrTrackingId)) {
      configsOrTrackingId.forEach((config) => {
        if (typeof config !== 'object') {
          this.warn('All configs must be an object')
          return
        }
        this._initialize(config.trackingId, config)
      })
    } else {
      this._initialize(configsOrTrackingId, options)
    }
    return true
  }

  initialize = (configsOrTrackingId, options) => {
    if (options && options.testMode === true) {
      this.testMode = true
    } else {
      if (this.isNotBrowser) {
        return
      }

      if (!options || options.standardImplementation !== true) this.loadGA(options)
    }

    this.alwaysSendToDefaultTracker = options && typeof options.alwaysSendToDefaultTracker === 'boolean'
      ? options.alwaysSendToDefaultTracker
      : true

    this.addTrackers(configsOrTrackingId, options)
  }

  /**
     * ga: Google Analytics
     * @returns {Object} the original GA object.
     */
  ga = (...args) => {
    if (args.length > 0) {
      this.internalGa(...args)
      if (this.debug) {
        this.this.log("called ga('arguments');")
        this.this.log(`with arguments: ${JSON.stringify(args)}`)
      }
    }

    return window.ga
  }

  /**
     * set: Set data to store on GA tracker
     * GA tracker set method
     * @param {Object} fieldsObject - a field/value pair or a group of field/value pairs on the tracker
     * @param {Array} trackerNames - (optional) a list of extra trackers to run the command on
     */
  set = (fieldsObject, trackerNames) => {
    if (!fieldsObject) {
      this.warn('`fieldsObject` is required in .set()')
      return
    }

    if (typeof fieldsObject !== 'object') {
      this.warn('Expected `fieldsObject` arg to be an Object')
      return
    }

    if (Object.keys(fieldsObject).length === 0) {
      this.warn('empty `fieldsObject` given to .set()')
    }

    this.gaCommand(trackerNames, 'set', fieldsObject)

    if (this._debug) {
      this.log("called ga('set', fieldsObject);")
      this.log(`with fieldsObject: ${JSON.stringify(fieldsObject)}`)
    }
  }

  /**
     * send:
     * Clone of the low level `ga.send` method
     * WARNING: No validations will be applied to this
     * @param  {Object} fieldObject - field object for tracking different analytics
     * @param  {Array} trackerNames - trackers to send the command to
     * @param {Array} trackerNames - (optional) a list of extra trackers to run the command on
     */
  send = (fieldObject, trackerNames) => {
    this.gaCommand(trackerNames, 'send', fieldObject)
    if (this._debug) {
      this.log("called ga('send', fieldObject);")
      this.log(`with fieldObject: ${JSON.stringify(fieldObject)}`)
      this.log(`with trackers: ${JSON.stringify(trackerNames)}`)
    }
  }

  /**
     * pageView:
     * Basic GA pageView tracking
     * @param  {String} path - the current page page e.g. '/about'
     * @param {Array} trackerNames - (optional) a list of extra trackers to run the command on
     * @param {String} title - (optional) the page title e. g. 'My Website'
     */
  pageView = (rawPath, trackerNames, title) => {
    if (!rawPath) {
      this.warn('path is required in .pageView()')
      return
    }

    const path = this.trim(rawPath)
    if (path === '') {
      this.warn('path cannot be an empty string in .pageView()')
      return
    }

    const extraFields = {}
    if (title) {
      extraFields.title = title
    }

    if (typeof ga === 'function') {
      this.gaCommand(trackerNames, 'send', {
        hitType: 'pageView',
        page: path,
        ...extraFields
      })

      if (this._debug) {
        this.log("called ga('send', 'pageView', path);")
        let extraLog = ''
        if (title) {
          extraLog = ` and title: ${title}`
        }
        this.log(`with path: ${path}${extraLog}`)
      }
    }
  }

  /**
     * modalView:
     * a proxy to basic GA pageView tracking to consistently track
     * modal views that are an equivalent UX to a traditional pageView
     * @param  {String} modalName e.g. 'add-or-edit-club'
     * @param {Array} trackerNames - (optional) a list of extra trackers to run the command on
     */
  modalView = (rawModalName, trackerNames) => {
    if (!rawModalName) {
      this.warn('modalName is required in .modalView(modalName)')
      return
    }

    const modalName = this.removeLeadingSlash(this.trim(rawModalName))

    if (modalName === '') {
      this.warn('modalName cannot be an empty string or a single / in .modalView()')
      return
    }

    if (typeof ga === 'function') {
      const path = `/modal/${modalName}`
      this.gaCommand(trackerNames, 'send', 'pageView', path)

      if (this._debug) {
        this.log("called ga('send', 'pageView', path);")
        this.log(`with path: ${path}`)
      }
    }
  }

  /**
     * timing:
     * GA timing
     * @param args.category {String} required
     * @param args.variable {String} required
     * @param args.value  {Int}  required
     * @param args.label  {String} required
     * @param {Array} trackerNames - (optional) a list of extra trackers to run the command on
     */
  timing = ({
    category, variable, value, label
  } = {}, trackerNames) => {
    if (typeof this.ga === 'function') {
      if (!category || !variable || typeof value !== 'number') {
        this.warn(
          'args.category, args.variable ' +
                    'AND args.value are required in timing() ' +
                    'AND args.value has to be a number'
        )
        return
      }

      // Required Fields
      const fieldObject = {
        hitType: 'timing',
        timingCategory: this._format(category),
        timingVar: this._format(variable),
        timingValue: value
      }

      if (label) {
        fieldObject.timingLabel = this._format(label)
      }

      this.send(fieldObject, trackerNames)
    }
  }

  /**
     * event:
     * GA event tracking
     * @param args.category {String} required
     * @param args.action {String} required
     * @param args.label {String} optional
     * @param args.value {Int} optional
     * @param args.nonInteraction {boolean} optional
     * @param args.transport {String} optional
     * @param {{action: String, category: String}} trackerNames - (optional) a list of extra trackers to run the command on
     */
  event = ({
    category, action, label, value, nonInteraction, transport, ...args
  } = {}, trackerNames) => {
    if (typeof this.ga === 'function') {
      // Simple Validation
      if (!category || !action) {
        this.warn('args.category AND args.action are required in event()')
        return
      }

      // Required Fields
      const fieldObject = {
        hitType: 'event',
        eventCategory: this._format(category),
        eventAction: this._format(action)
      }

      // Optional Fields
      if (label) {
        fieldObject.eventLabel = this._format(label)
      }

      if (typeof value !== 'undefined') {
        if (typeof value !== 'number') {
          this.warn('Expected `args.value` arg to be a Number.')
        } else {
          fieldObject.eventValue = value
        }
      }

      if (typeof nonInteraction !== 'undefined') {
        if (typeof nonInteraction !== 'boolean') {
          this.warn('`args.nonInteraction` must be a boolean.')
        } else {
          fieldObject.nonInteraction = nonInteraction
        }
      }

      if (typeof transport !== 'undefined') {
        if (typeof transport !== 'string') {
          this.warn('`args.transport` must be a string.')
        } else {
          if (['beacon', 'xhr', 'image'].indexOf(transport) === -1) {
            this.warn(
              '`args.transport` must be either one of these values: `beacon`, `xhr` or `image`'
            )
          }

          fieldObject.transport = transport
        }
      }

      Object.keys(args)
        .filter((key) => key.substr(0, 'dimension'.length) === 'dimension')
        .forEach((key) => {
          fieldObject[key] = args[key]
        })

      Object.keys(args)
        .filter((key) => key.substr(0, 'metric'.length) === 'metric')
        .forEach((key) => {
          fieldObject[key] = args[key]
        })

      // Send to GA
      this.send(fieldObject, trackerNames)
    }
  }

  /**
     * exception:
     * GA exception tracking
     * @param args.description {String} optional
     * @param args.fatal {boolean} optional
     * @param {Array} trackerNames - (optional) a list of extra trackers to run the command on
     */
  exception = ({ description, fatal }, trackerNames) => {
    if (typeof this.ga === 'function') {
      // Required Fields
      const fieldObject = {
        hitType: 'exception'
      }

      // Optional Fields
      if (description) {
        fieldObject.exDescription = this._format(description)
      }

      if (typeof fatal !== 'undefined') {
        if (typeof fatal !== 'boolean') {
          this.warn('`args.fatal` must be a boolean.')
        } else {
          fieldObject.exFatal = fatal
        }
      }

      // Send to GA
      this.send(fieldObject, trackerNames)
    }
  }

  plugin = {
    /**
         * require:
         * GA requires a plugin
         * @param name {String} e.g. 'ecommerce' or 'myplugin'
         * @param options {Object} optional e.g {path: '/log', debug: true}
         * @param trackerName {String} optional e.g 'trackerName'
         */
    require: (rawName, options, trackerName) => {
      if (typeof this.ga === 'function') {
        // Required Fields
        if (!rawName) {
          this.warn('`name` is required in .require()')
          return
        }

        const name = this.trim(rawName)
        if (name === '') {
          this.warn('`name` cannot be an empty string in .require()')
          return
        }
        const requireString = trackerName ? `${trackerName}.require` : 'require'
        // Optional Fields
        if (options) {
          if (typeof options !== 'object') {
            this.warn('Expected `options` arg to be an Object')
            return
          }

          if (Object.keys(options).length === 0) {
            this.warn('Empty `options` given to .require()')
          }

          this.ga(requireString, name, options)

          if (this.debug) {
            this.log(`called ga('require', '${name}', ${JSON.stringify(options)}`)
          }
        } else {
          this.ga(requireString, name)

          if (this.debug) {
            this.log(`called ga('require', '${name}');`)
          }
        }
      }
    },
    /**
         * execute:
         * GA execute action for plugin
         * Takes variable number of arguments
         * @param pluginName {String} e.g. 'e-commerce' or 'myPlugin'
         * @param action {String} e.g. 'addItem' or 'myCustomAction'
         * @param actionType {String} optional e.g. 'detail'
         * @param payload {Object} optional e.g { id: '1x5e', name : 'My product to track' }
         */
    execute: (pluginName, action, ...args) => {
      let payload
      let actionType

      if (args.length === 1) {
        [payload] = args
      } else {
        [actionType, payload] = args
      }

      if (typeof this.ga === 'function') {
        if (typeof pluginName !== 'string') {
          this.warn('Expected `pluginName` arg to be a String.')
        } else if (typeof action !== 'string') {
          this.warn('Expected `action` arg to be a String.')
        } else {
          const command = `${pluginName}:${action}`
          payload = payload || null
          if (actionType && payload) {
            this.ga(command, actionType, payload)
            if (this.debug) {
              this.log(`called ga('${command}');`)
              this.log(
                                `actionType: "${actionType}" with payload: ${JSON.stringify(
                                    payload
                                )}`
              )
            }
          } else if (payload) {
            this.ga(command, payload)
            if (this.debug) {
              this.log(`called ga('${command}');`)
              this.log(`with payload: ${JSON.stringify(payload)}`)
            }
          } else {
            this.ga(command)
            if (this.debug) {
              this.log(`called ga('${command}');`)
            }
          }
        }
      }
    }
  }

  /**
     * outboundLink:
     * GA outboundLink tracking
     * @param args.label {String} e.g. url, or 'Create an Account'
     * @param {function} hitCallback - Called after processing a hit.
     */
  outboundLink = (args, hitCallback, trackerNames) => {
    if (typeof hitCallback !== 'function') {
      this.warn('hitCallback function is required')
      return
    }

    if (typeof this.ga === 'function') {
      // Simple Validation
      if (!args || !args.label) {
        this.warn('args.label is required in outboundLink()')
        return
      }

      // Required Fields
      const fieldObject = {
        hitType: 'event',
        eventCategory: 'Outbound',
        eventAction: 'Click',
        eventLabel: this._format(args.label)
      }

      let safetyCallbackCalled = false
      const safetyCallback = () => {
        // This prevents a delayed response from GA
        // causing hitCallback from being fired twice
        safetyCallbackCalled = true

        hitCallback()
      }
      // Using a timeout to ensure the execution of critical application code
      // in the case when the GA server might be down
      // or an ad blocker prevents sending the data

      // register safety net timeout:
      const t = setTimeout(safetyCallback, 250)

      const clearableCallbackForGA = () => {
        clearTimeout(t)
        if (!safetyCallbackCalled) {
          hitCallback()
        }
      }

      fieldObject.hitCallback = clearableCallbackForGA

      // Send to GA
      this.send(fieldObject, trackerNames)
    } else {
      // if ga is not defined, return the callback so the application
      // continues to work as expected
      setTimeout(hitCallback, 0)
    }
  }
}

const Controller = new GA()

const GAComponent = () => {
  const location = useLocation()
  const initGAForReact = () => {
    if (window.melodicjeaniousUtils.GA.options.enable) Controller.initialize(window.melodicjeaniousUtils.GA.id)
  }
  const logPageForReact = useCallback(
    (path, search, options) => {
      const page = path + search
      Controller.set({ page, location: `${window.location.origin}${page}`, ...options })
      Controller.pageView(page)
    }, []
  )
  initGAForReact()
  useEffect(() => {
    initGAForReact()
  }, [])
  useEffect(() => {
    const { pathname, search } = location
    logPageForReact(pathname, search)
  }, [location, logPageForReact])

  useEffect(() => {
    const { pathname, search } = location
    logPageForReact(pathname, search)
  })
  return null
}

const gaExport = {
  GAComponent,
  Controller
}
export default gaExport

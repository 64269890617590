import React from 'react'
// eslint-disable-next-line no-unused-vars
import { Pane, Alert, Heading, majorScale, Card, Paragraph, Strong, Button, minorScale } from 'evergreen-ui'
import { Device } from '../../components/responsiveness'

export default function BookingConfirmed () {
  return (
    <>
      <Device desktop>
        <Pane display="flex" flexDirection="column" paddingX={majorScale(32)} paddingY={majorScale(16)} background="gray50">
          <Alert intent="success" alignSelf="center" width={majorScale(64)} marginBottom={majorScale(8)}>Booking Confirmed</Alert>
          <Heading size={700} color="#101840" alignSelf="center" marginBottom={majorScale(4)}>STEP 1: MUST WATCH</Heading>
          <Heading size={500} color="#101840" alignSelf="center" marginBottom={majorScale(8)}>Please watch this short 4 minute video so you know how to prepare for your call.</Heading>
          <Pane display="flex" alignItems="center" alignSelf="center" flexDirection="column" height={majorScale(64)} width={majorScale(128)} marginBottom={majorScale(8)}>
            <iframe height="100%" width="100%" src="https://www.youtube.com/embed/Cj4W6IKsw1Y" title="Richiedagenius Booking Confirmation Video" frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
          </Pane>
          <Heading size={300} color="#101840" alignSelf="center" marginBottom={majorScale(8)}>Your pre-call checklist:</Heading>
          <Pane display="flex" flexDirection="row" alignItems="center" justifyContent="space-evenly" marginBottom={majorScale(4)}>
            <Card background="gray800" border="muted" borderColor="#101840" width={majorScale(32)} height={majorScale(8)} padding={majorScale(2)} textAlign="center" borderRadius={majorScale(1)}>
              <Paragraph size="small" color="white">1 - Good WiFi connection</Paragraph>
            </Card>
            <Card background="gray800" border="muted" borderColor="#101840" width={majorScale(32)} height={majorScale(8)} padding={majorScale(2)} textAlign="center" borderRadius={majorScale(1)}>
              <Paragraph size="small" color="white">2 - Quiet room</Paragraph>
            </Card>
            <Card background="gray800" border="muted" borderColor="#101840" width={majorScale(32)} height={majorScale(8)} padding={majorScale(2)} textAlign="center" borderRadius={majorScale(1)}>
              <Paragraph size="small" color="white">3 - All decision-makers present</Paragraph>
            </Card>
          </Pane>
          <Pane display="flex" flexDirection="row" alignItems="center" justifyContent="space-evenly" marginBottom={majorScale(4)}>
            <Card background="gray800" border="muted" borderColor="#101840" width={majorScale(32)} height={majorScale(8)} padding={majorScale(2)} textAlign="center" borderRadius={majorScale(1)}>
              <Paragraph size="small" color="white">4 - Bring a pen & paper</Paragraph>
            </Card>
            <Card background="gray800" border="muted" borderColor="#101840" width={majorScale(32)} height={majorScale(8)} padding={majorScale(2)} textAlign="center" borderRadius={majorScale(1)}>
              <Paragraph size="small" color="white">5 - Prepare any questions</Paragraph>
            </Card>
            <Card background="gray800" border="muted" borderColor="#101840" width={majorScale(32)} height={majorScale(8)} padding={majorScale(2)} textAlign="center" borderRadius={majorScale(1)}>
              <Paragraph size="small" color="white">6 - Bring your wallet</Paragraph>
            </Card>
          </Pane>
          <Pane display="flex" flexDirection="row" alignItems="center" justifyContent="space-evenly" marginBottom={majorScale(4)}>
            <Card background="gray800" border="muted" borderColor="#101840" width={majorScale(32)} height={majorScale(8)} padding={majorScale(2)} textAlign="center" borderRadius={majorScale(1)}>
              <Paragraph size="small" color="white">7 - Check out our testimonials</Paragraph>
            </Card>
            <Card background="gray800" border="muted" borderColor="#101840" width={majorScale(32)} height={majorScale(8)} padding={majorScale(2)} textAlign="center" borderRadius={majorScale(1)}>
              <Paragraph size="small" color="white">8 - Watch Program Demo</Paragraph>
            </Card>
            <Card background="gray800" border="muted" borderColor="#101840" width={majorScale(32)} height={majorScale(8)} padding={majorScale(2)} textAlign="center" borderRadius={majorScale(1)}>
              <Paragraph size="small" color="white">9 - Watch FREE 15 min masterclass</Paragraph>
            </Card>
          </Pane>
          <Pane padding={majorScale(4)}></Pane>
        </Pane>
        <Pane display="flex" flexDirection="column" background="gray90" width="100vw" paddingX={majorScale(32)} paddingY={majorScale(16)}>
          <Heading size={700} color="#101840" alignSelf="center" marginBottom={majorScale(4)}>STEP 2: STUDENTS WINS</Heading>
          <Heading size={500} color="#101840" alignSelf="center" marginBottom={majorScale(8)}>Here&apos;s a handful of our Student Wins & testimonials you can check out</Heading>
          <Pane display="flex" flexDirection="row" alignItems="center" justifyContent="space-evenly" marginBottom={majorScale(4)}>
            <img width={majorScale(40)} height={majorScale(40)} src="https://melodicjeanious-booking.s3.amazonaws.com/richiedagenius_booking-web-testimonials_1.png"></img>
            <img width={majorScale(40)} height={majorScale(40)} src="https://melodicjeanious-booking.s3.amazonaws.com/richiedagenius_booking-web-testimonials_2.png"></img>
            <img width={majorScale(40)} height={majorScale(40)} src="https://melodicjeanious-booking.s3.amazonaws.com/richiedagenius_booking-web-testimonials_3.png"></img>
          </Pane>
          <Pane display="flex" flexDirection="row" alignItems="center" justifyContent="space-evenly" marginBottom={majorScale(4)}>
            <img width={majorScale(40)} height={majorScale(40)} src="https://melodicjeanious-booking.s3.amazonaws.com/richiedagenius_booking-web-testimonials_4.png"></img>
            <img width={majorScale(40)} height={majorScale(40)} src="https://melodicjeanious-booking.s3.amazonaws.com/richiedagenius_booking-web-testimonials_5.png"></img>
            <img width={majorScale(40)} height={majorScale(40)} src="https://melodicjeanious-booking.s3.amazonaws.com/richiedagenius_booking-web-testimonials_6.png"></img>
          </Pane>
          <Heading size={700} color="#101840" alignSelf="center" marginTop={majorScale(8)} marginBottom={majorScale(8)}>Interviews with past students</Heading>
          <Pane display="flex" flexDirection="row" alignItems="center" justifyContent="space-evenly" marginBottom={majorScale(4)}>
            <Pane display="flex" alignItems="center" alignSelf="center" flexDirection="column" height={majorScale(24)} width={majorScale(48)} marginBottom={majorScale(8)}>
              <iframe height="100%" width="100%" src="https://www.youtube.com/embed/val26PstMok" title="Richiedagenius Previous student 1" frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
            </Pane>
            <Pane display="flex" alignItems="center" alignSelf="center" flexDirection="column" height={majorScale(24)} width={majorScale(48)} marginBottom={majorScale(8)}>
              <iframe height="100%" width="100%" src="https://www.youtube.com/embed/syaP0Z-lZNM" title="Richiedagenius Previous student 2" frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
            </Pane>
            <Pane display="flex" alignItems="center" alignSelf="center" flexDirection="column" height={majorScale(24)} width={majorScale(48)} marginBottom={majorScale(8)}>
              <iframe height="100%" width="100%" src="https://www.youtube.com/embed/uDMrcO8eNWo" title="Richiedagenius Previous student 3" frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
            </Pane>
          </Pane>
          <Pane display="flex" flexDirection="row" alignItems="center" justifyContent="space-evenly">
            <Pane display="flex" alignItems="center" alignSelf="center" flexDirection="column" height={majorScale(24)} width={majorScale(48)} marginBottom={majorScale(8)}>
              <iframe height="100%" width="100%" src="https://www.youtube.com/embed/SKCavXKLLE0" title="Richiedagenius Previous student 4" frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
            </Pane>
            <Pane display="flex" alignItems="center" alignSelf="center" flexDirection="column" height={majorScale(24)} width={majorScale(48)} marginBottom={majorScale(8)}>
              <iframe height="100%" width="100%" src="https://www.youtube.com/embed/l8FEWjfwmrY" title="Richiedagenius Previous student 5" frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
            </Pane>
            <Pane display="flex" alignItems="center" alignSelf="center" flexDirection="column" height={majorScale(24)} width={majorScale(48)} marginBottom={majorScale(8)}>
              <iframe height="100%" width="100%" src="https://www.youtube.com/embed/o8V7Bf4PX2g" title="Richiedagenius Previous student 6" frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
            </Pane>
          </Pane>
        </Pane>
        <Pane display="flex" flexDirection="column" paddingX={majorScale(32)} paddingY={majorScale(16)} background="gray50">
          <Heading size={700} color="#101840" alignSelf="center" marginBottom={majorScale(4)}>STEP 3: OUR PROGRAM</Heading>
          <Heading size={500} color="#101840" alignSelf="center" marginBottom={majorScale(8)}>This short video will explain our program and how it works</Heading>
          <Pane display="flex" alignItems="center" alignSelf="center" flexDirection="column" height={majorScale(64)} width={majorScale(128)} marginBottom={majorScale(8)}>
            <iframe height="100%" width="100%" src="https://www.youtube.com/embed/uGpqYgbvlAo" title="Program demo" frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
          </Pane>
        </Pane>
        {/* <Pane display="flex" flexDirection="column" backgroundColor="#FFD079" width="100vw" paddingX={majorScale(32)} paddingY={majorScale(8)}>
          <Card background="white" border="muted" borderColor="#D8DAE5" padding={majorScale(2)} marginBottom={majorScale(4)} textAlign="center" alignSelf="center" width="fit-content" height="100%" borderRadius={majorScale(1)}>
            <Strong size={300} color="#101840">HIGHLY RECOMMENDED - Watch before your call</Strong>
          </Card>
          <Heading size={900} color="#101840" alignSelf="center" marginBottom={majorScale(3)} textAlign="center" width={majorScale(64)}>STEP 4: FREE 15 MINUTE MASTERCLASS</Heading>
          <Heading size={500} color="#101840" alignSelf="center" marginBottom={majorScale(4)} textAlign="center" width={majorScale(72)}>How I learnt mix engineering from novice to expert using core frameworks and the 3 things you need to do the same.</Heading>
          <Button appearance="primary" size="large" backgroundColor="#474D66" borderColor="#474D66" alignSelf="center" width={majorScale(16)}>WATCH NOW</Button>
        </Pane> */}
        <Pane display="flex" flexDirection="column" background="gray900" width="100vw" paddingX={majorScale(32)} paddingY={majorScale(8)}>
          <Paragraph color="#696F8C" textAlign="center">Melodicjeanious Academy © 2022, All rights reserved.</Paragraph>
        </Pane>
      </Device>
      <Device mobile>
        <Pane display="flex" flexDirection="column" paddingX={minorScale(4)} paddingY={minorScale(8)} background="gray50">
          <Alert intent="success" alignSelf="center" width={minorScale(64)} marginBottom={minorScale(8)}>Booking Confirmed</Alert>
          <Heading size={700} color="#101840" alignSelf="center" marginBottom={minorScale(4)}>STEP 1: MUST WATCH</Heading>
          <Heading size={500} color="#101840" alignSelf="center" textAlign="center" marginBottom={minorScale(8)}>Please watch this short 4 minute video so you know how to prepare for your call.</Heading>
          <Pane display="flex" alignItems="center" alignSelf="center" flexDirection="column" height={minorScale(64)} width="100%" marginBottom={minorScale(8)}>
            <iframe height="100%" width="100%" src="https://www.youtube.com/embed/Cj4W6IKsw1Y" title="Richiedagenius Booking Confirmation Video" frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
          </Pane>
          <Heading size={300} color="#101840" alignSelf="center" marginBottom={minorScale(8)}>Your pre-call checklist:</Heading>
          <Pane display="flex" flexDirection="column" alignItems="center" marginBottom={minorScale(4)}>
            <Card background="gray800" border="muted" borderColor="#101840" width="80%" height="fit-content" padding={minorScale(2)} textAlign="center" borderRadius={minorScale(1)} marginBottom={minorScale(8)}>
              <Paragraph size="small" color="white">1 - Good WiFi connection</Paragraph>
            </Card>
            <Card background="gray800" border="muted" borderColor="#101840" width="80%" height="fit-content" padding={minorScale(2)} textAlign="center" borderRadius={minorScale(1)} marginBottom={minorScale(8)}>
              <Paragraph size="small" color="white">2 - Quiet room</Paragraph>
            </Card>
            <Card background="gray800" border="muted" borderColor="#101840" width="80%" height="fit-content" padding={minorScale(2)} textAlign="center" borderRadius={minorScale(1)} marginBottom={minorScale(8)}>
              <Paragraph size="small" color="white">3 - All decision-makers present</Paragraph>
            </Card>
            <Card background="gray800" border="muted" borderColor="#101840" width="80%" height="fit-content" padding={minorScale(2)} textAlign="center" borderRadius={minorScale(1)} marginBottom={minorScale(8)}>
              <Paragraph size="small" color="white">4 - Bring a pen & paper</Paragraph>
            </Card>
            <Card background="gray800" border="muted" borderColor="#101840" width="80%" height="fit-content" padding={minorScale(2)} textAlign="center" borderRadius={minorScale(1)} marginBottom={minorScale(8)}>
              <Paragraph size="small" color="white">5 - Prepare any questions</Paragraph>
            </Card>
            <Card background="gray800" border="muted" borderColor="#101840" width="80%" height="fit-content" padding={minorScale(2)} textAlign="center" borderRadius={minorScale(1)} marginBottom={minorScale(8)}>
              <Paragraph size="small" color="white">6 - Bring your wallet</Paragraph>
            </Card>
            <Card background="gray800" border="muted" borderColor="#101840" width="80%" height="fit-content" padding={minorScale(2)} textAlign="center" borderRadius={minorScale(1)} marginBottom={minorScale(8)}>
              <Paragraph size="small" color="white">7 - Check out our testimonials</Paragraph>
            </Card>
            <Card background="gray800" border="muted" borderColor="#101840" width="80%" height="fit-content" padding={minorScale(2)} textAlign="center" borderRadius={minorScale(1)} marginBottom={minorScale(8)}>
              <Paragraph size="small" color="white">8 - Watch Program Demo</Paragraph>
            </Card>
            <Card background="gray800" border="muted" borderColor="#101840" width="80%" height="fit-content" padding={minorScale(2)} textAlign="center" borderRadius={minorScale(1)} marginBottom={minorScale(8)}>
              <Paragraph size="small" color="white">9 - Watch FREE 15 min masterclass</Paragraph>
            </Card>
          </Pane>
        </Pane>
        <Pane display="flex" flexDirection="column" background="gray90" width="100%" paddingX={minorScale(4)} paddingY={minorScale(8)}>
          <Heading size={700} color="#101840" alignSelf="center" marginBottom={minorScale(4)}>STEP 2: STUDENTS WINS</Heading>
          <Heading size={500} color="#101840" alignSelf="center" textAlign="center" marginBottom={minorScale(8)}>Here&apos;s a handful of our Student Wins & testimonials you can check out</Heading>
          <Pane display="flex" flexDirection="column" alignItems="center" marginBottom={minorScale(4)}>
            <img width={minorScale(56)} height={minorScale(56)} style={{ marginBottom: minorScale(8) }} src="https://melodicjeanious-booking.s3.amazonaws.com/richiedagenius_booking-web-testimonials_1.png"></img>
            <img width={minorScale(56)} height={minorScale(56)} style={{ marginBottom: minorScale(8) }} src="https://melodicjeanious-booking.s3.amazonaws.com/richiedagenius_booking-web-testimonials_2.png"></img>
            <img width={minorScale(56)} height={minorScale(56)} style={{ marginBottom: minorScale(8) }} src="https://melodicjeanious-booking.s3.amazonaws.com/richiedagenius_booking-web-testimonials_3.png"></img>
            <img width={minorScale(56)} height={minorScale(56)} style={{ marginBottom: minorScale(8) }} src="https://melodicjeanious-booking.s3.amazonaws.com/richiedagenius_booking-web-testimonials_4.png"></img>
            <img width={minorScale(56)} height={minorScale(56)} style={{ marginBottom: minorScale(8) }} src="https://melodicjeanious-booking.s3.amazonaws.com/richiedagenius_booking-web-testimonials_5.png"></img>
            <img width={minorScale(56)} height={minorScale(56)} style={{ marginBottom: minorScale(8) }} src="https://melodicjeanious-booking.s3.amazonaws.com/richiedagenius_booking-web-testimonials_6.png"></img>
          </Pane>
          <Heading size={700} color="#101840" alignSelf="center" marginTop={minorScale(8)} marginBottom={minorScale(8)}>Interviews with past students</Heading>
          <Pane display="flex" flexDirection="column" alignItems="center" marginBottom={minorScale(4)}>
            <Pane display="flex" alignItems="center" alignSelf="center" flexDirection="column" height="auto" width="100%" marginBottom={minorScale(8)}>
              <iframe height="100%" width="100%" src="https://www.youtube.com/embed/val26PstMok" title="Richiedagenius previous student 1" frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
            </Pane>
            <Pane display="flex" alignItems="center" alignSelf="center" flexDirection="column" height="auto" width="100%" marginBottom={minorScale(8)}>
              <iframe height="100%" width="100%" src="https://www.youtube.com/embed/syaP0Z-lZNM" title="Richiedagenius previous student 2" frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
            </Pane>
            <Pane display="flex" alignItems="center" alignSelf="center" flexDirection="column" height="auto" width="100%" marginBottom={minorScale(8)}>
              <iframe height="100%" width="100%" src="https://www.youtube.com/embed/uDMrcO8eNWo" title="Richiedagenius previous student 3" frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
            </Pane>
            <Pane display="flex" alignItems="center" alignSelf="center" flexDirection="column" height="auto" width="100%" marginBottom={minorScale(8)}>
              <iframe height="100%" width="100%" src="https://www.youtube.com/embed/SKCavXKLLE0" title="Richiedagenius previous student 4" frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
            </Pane>
            <Pane display="flex" alignItems="center" alignSelf="center" flexDirection="column" height="auto" width="100%" marginBottom={minorScale(8)}>
              <iframe height="100%" width="100%" src="https://www.youtube.com/embed/l8FEWjfwmrY" title="Richiedagenius previous student 5" frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
            </Pane>
            <Pane display="flex" alignItems="center" alignSelf="center" flexDirection="column" height="auto" width="100%" marginBottom={minorScale(8)}>
              <iframe height="100%" width="100%" src="https://www.youtube.com/embed/o8V7Bf4PX2g" title="Richiedagenius previous student 6" frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
            </Pane>
          </Pane>
        </Pane>
        <Pane display="flex" flexDirection="column" paddingX={minorScale(4)} paddingY={minorScale(8)} background="gray50">
          <Heading size={700} color="#101840" alignSelf="center" marginBottom={minorScale(4)}>STEP 3: OUR PROGRAM</Heading>
          <Heading size={500} color="#101840" alignSelf="center" textAlign="center" marginBottom={minorScale(8)}>This short video will explain our program and how it works</Heading>
          <Pane display="flex" alignItems="center" alignSelf="center" flexDirection="column" height={minorScale(64)} width="100%" marginBottom={minorScale(8)}>
            <iframe height="100%" width="100%" src="https://www.youtube.com/embed/uGpqYgbvlAo" title="Program demo" frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
          </Pane>
        </Pane>
        {/* <Pane display="flex" flexDirection="column" backgroundColor="#FFD079" width="100%" paddingX={minorScale(4)} paddingY={minorScale(8)}>
          <Card background="white" border="muted" borderColor="#D8DAE5" padding={minorScale(2)} marginBottom={minorScale(8)} textAlign="center" alignSelf="center" width={minorScale(64)} height="100%" borderRadius={minorScale(1)}>
            <Strong size={300} color="#101840">HIGHLY RECOMMENDED - Watch before your call</Strong>
          </Card>
          <Heading size={900} color="#101840" alignSelf="center" marginBottom={minorScale(6)} textAlign="center" width={minorScale(64)}>STEP 4: FREE 15 MINUTE MASTERCLASS</Heading>
          <Heading size={500} color="#101840" alignSelf="center" marginBottom={minorScale(4)} textAlign="center" width={minorScale(72)}>How I learnt mix engineering from novice to expert using core frameworks and the 3 things you need to do the same.</Heading>
          <Button appearance="primary" size="large" backgroundColor="#474D66" borderColor="#474D66" alignSelf="center" width={minorScale(32)}>WATCH NOW</Button>
        </Pane> */}
        <Pane display="flex" flexDirection="column" background="gray900" width="100%" paddingX={minorScale(4)} paddingY={minorScale(8)}>
          <Paragraph color="#696F8C" textAlign="center">Melodicjeanious Academy © 2022, All rights reserved.</Paragraph>
        </Pane>
      </Device>
      <Device tablet>
        <Pane display="flex" flexDirection="column" paddingX={majorScale(16)} paddingY={majorScale(16)} background="gray50">
          <Alert intent="success" alignSelf="center" width={majorScale(64)} marginBottom={majorScale(8)}>Booking Confirmed</Alert>
          <Heading size={700} color="#101840" alignSelf="center" marginBottom={majorScale(4)}>STEP 1: MUST WATCH</Heading>
          <Heading size={500} color="#101840" alignSelf="center" textAlign="center" marginBottom={majorScale(8)}>Please watch this short 4 minute video so you know how to prepare for your call.</Heading>
          <Pane display="flex" alignItems="center" alignSelf="center" flexDirection="column" height={majorScale(64)} width="100%" marginBottom={majorScale(8)}>
            <iframe height="100%" width="100%" src="https://www.youtube.com/embed/Cj4W6IKsw1Y" title="Richiedagenius Booking Confirmation Video" frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
          </Pane>
          <Heading size={300} color="#101840" alignSelf="center" marginBottom={majorScale(8)}>Your pre-call checklist:</Heading>
          <Pane display="flex" flexDirection="row" alignItems="center" justifyContent="space-evenly" marginBottom={majorScale(4)}>
            <Card background="gray800" border="muted" borderColor="#101840" width={majorScale(32)} height={majorScale(8)} padding={majorScale(2)} textAlign="center" borderRadius={majorScale(1)}>
              <Paragraph size="small" color="white">1 - Good WiFi connection</Paragraph>
            </Card>
            <Card background="gray800" border="muted" borderColor="#101840" width={majorScale(32)} height={majorScale(8)} padding={majorScale(2)} textAlign="center" borderRadius={majorScale(1)}>
              <Paragraph size="small" color="white">2 - Quiet room</Paragraph>
            </Card>
          </Pane>
          <Pane display="flex" flexDirection="row" alignItems="center" justifyContent="space-evenly" marginBottom={majorScale(4)}>
            <Card background="gray800" border="muted" borderColor="#101840" width={majorScale(32)} height={majorScale(8)} padding={majorScale(2)} textAlign="center" borderRadius={majorScale(1)}>
              <Paragraph size="small" color="white">3 - All decision-makers present</Paragraph>
            </Card>
            <Card background="gray800" border="muted" borderColor="#101840" width={majorScale(32)} height={majorScale(8)} padding={majorScale(2)} textAlign="center" borderRadius={majorScale(1)}>
              <Paragraph size="small" color="white">4 - Bring a pen & paper</Paragraph>
            </Card>
          </Pane>
          <Pane display="flex" flexDirection="row" alignItems="center" justifyContent="space-evenly" marginBottom={majorScale(4)}>
            <Card background="gray800" border="muted" borderColor="#101840" width={majorScale(32)} height={majorScale(8)} padding={majorScale(2)} textAlign="center" borderRadius={majorScale(1)}>
              <Paragraph size="small" color="white">5 - Prepare any questions</Paragraph>
            </Card>
            <Card background="gray800" border="muted" borderColor="#101840" width={majorScale(32)} height={majorScale(8)} padding={majorScale(2)} textAlign="center" borderRadius={majorScale(1)}>
              <Paragraph size="small" color="white">6 - Bring your wallet</Paragraph>
            </Card>
          </Pane>
          <Pane display="flex" flexDirection="row" alignItems="center" justifyContent="space-evenly" marginBottom={majorScale(4)}>
            <Card background="gray800" border="muted" borderColor="#101840" width={majorScale(32)} height={majorScale(8)} padding={majorScale(2)} textAlign="center" borderRadius={majorScale(1)}>
              <Paragraph size="small" color="white">7 - Check out our testimonials</Paragraph>
            </Card>
            <Card background="gray800" border="muted" borderColor="#101840" width={majorScale(32)} height={majorScale(8)} padding={majorScale(2)} textAlign="center" borderRadius={majorScale(1)}>
              <Paragraph size="small" color="white">8 - Watch Program Demo</Paragraph>
            </Card>
          </Pane>
          <Pane display="flex" flexDirection="row" alignItems="center" justifyContent="space-evenly" marginBottom={majorScale(4)}>
            <Card background="gray800" border="muted" borderColor="#101840" width={majorScale(32)} height={majorScale(8)} padding={majorScale(2)} textAlign="center" borderRadius={majorScale(1)}>
              <Paragraph size="small" color="white">9 - Watch FREE 15 min masterclass</Paragraph>
            </Card>
          </Pane>
          <Pane padding={majorScale(4)}></Pane>
        </Pane>
        <Pane display="flex" flexDirection="column" background="gray90" width="100vw" paddingX={majorScale(16)} paddingY={majorScale(16)}>
          <Heading size={700} color="#101840" alignSelf="center" marginBottom={majorScale(4)}>STEP 2: STUDENTS WINS</Heading>
          <Heading size={500} color="#101840" alignSelf="center" textAlign="center" marginBottom={majorScale(8)}>Here&apos;s a handful of our Student Wins & testimonials you can check out</Heading>
          <Pane display="flex" flexDirection="row" alignItems="center" justifyContent="space-evenly" marginBottom={majorScale(4)}>
            <img width={majorScale(40)} height={majorScale(40)} src="https://melodicjeanious-booking.s3.amazonaws.com/richiedagenius_booking-web-testimonials_1.png"></img>
            <img width={majorScale(40)} height={majorScale(40)} src="https://melodicjeanious-booking.s3.amazonaws.com/richiedagenius_booking-web-testimonials_2.png"></img>
          </Pane>
          <Pane display="flex" flexDirection="row" alignItems="center" justifyContent="space-evenly" marginBottom={majorScale(4)}>
            <img width={majorScale(40)} height={majorScale(40)} src="https://melodicjeanious-booking.s3.amazonaws.com/richiedagenius_booking-web-testimonials_3.png"></img>
            <img width={majorScale(40)} height={majorScale(40)} src="https://melodicjeanious-booking.s3.amazonaws.com/richiedagenius_booking-web-testimonials_4.png"></img>
          </Pane>
          <Pane display="flex" flexDirection="row" alignItems="center" justifyContent="space-evenly" marginBottom={majorScale(4)}>
            <img width={majorScale(40)} height={majorScale(40)} src="https://melodicjeanious-booking.s3.amazonaws.com/richiedagenius_booking-web-testimonials_5.png"></img>
            <img width={majorScale(40)} height={majorScale(40)} src="https://melodicjeanious-booking.s3.amazonaws.com/richiedagenius_booking-web-testimonials_6.png"></img>
          </Pane>
          <Heading size={700} color="#101840" alignSelf="center" marginTop={majorScale(8)} marginBottom={majorScale(8)}>Interviews with past students</Heading>
          <Pane display="flex" flexDirection="row" alignItems="center" justifyContent="space-evenly">
            <Pane display="flex" alignItems="center" alignSelf="center" flexDirection="column" height={majorScale(24)} width={majorScale(48)} marginBottom={majorScale(8)}>
              <iframe height="100%" width="100%" src="https://www.youtube.com/embed/val26PstMok" title="Richiedagenius previous student 1" frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
            </Pane>
            <Pane display="flex" alignItems="center" alignSelf="center" flexDirection="column" height={majorScale(24)} width={majorScale(48)} marginBottom={majorScale(8)}>
              <iframe height="100%" width="100%" src="https://www.youtube.com/embed/syaP0Z-lZNM" title="Richiedagenius previous student 2" frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
            </Pane>
          </Pane>
          <Pane display="flex" flexDirection="row" alignItems="center" justifyContent="space-evenly">
            <Pane display="flex" alignItems="center" alignSelf="center" flexDirection="column" height={majorScale(24)} width={majorScale(48)} marginBottom={majorScale(8)}>
              <iframe height="100%" width="100%" src="https://www.youtube.com/embed/uDMrcO8eNWo" title="Richiedagenius previous student 3" frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
            </Pane>
            <Pane display="flex" alignItems="center" alignSelf="center" flexDirection="column" height={majorScale(24)} width={majorScale(48)} marginBottom={majorScale(8)}>
              <iframe height="100%" width="100%" src="https://www.youtube.com/embed/SKCavXKLLE0" title="Richiedagenius previous student 4" frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
            </Pane>
          </Pane>
          <Pane display="flex" flexDirection="row" alignItems="center" justifyContent="space-evenly">
            <Pane display="flex" alignItems="center" alignSelf="center" flexDirection="column" height={majorScale(24)} width={majorScale(48)} marginBottom={majorScale(8)}>
              <iframe height="100%" width="100%" src="https://www.youtube.com/embed/l8FEWjfwmrY" title="Richiedagenius previous student 5" frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
            </Pane>
            <Pane display="flex" alignItems="center" alignSelf="center" flexDirection="column" height={majorScale(24)} width={majorScale(48)} marginBottom={majorScale(8)}>
              <iframe height="100%" width="100%" src="https://www.youtube.com/embed/o8V7Bf4PX2g" title="Richiedagenius previous student 6" frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
            </Pane>
          </Pane>
        </Pane>
        <Pane display="flex" flexDirection="column" paddingX={majorScale(16)} paddingY={majorScale(16)} background="gray50">
          <Heading size={700} color="#101840" alignSelf="center" marginBottom={majorScale(4)}>STEP 3: OUR PROGRAM</Heading>
          <Heading size={500} color="#101840" alignSelf="center" textAlign="center" marginBottom={majorScale(8)}>This short video will explain our program and how it works</Heading>
          <Pane display="flex" alignItems="center" alignSelf="center" flexDirection="column" height={majorScale(64)} width="100%" marginBottom={majorScale(8)}>
            <iframe height="100%" width="100%" src="https://www.youtube.com/embed/uGpqYgbvlAo" title="Program demo" frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
          </Pane>
        </Pane>
        {/* <Pane display="flex" flexDirection="column" backgroundColor="#FFD079" width="100vw" paddingX={majorScale(16)} paddingY={majorScale(16)}>
          <Card background="white" border="muted" borderColor="#D8DAE5" padding={majorScale(2)} marginBottom={majorScale(4)} textAlign="center" alignSelf="center" width="fit-content" height="100%" borderRadius={majorScale(1)}>
            <Strong size={300} color="#101840">HIGHLY RECOMMENDED - Watch before your call</Strong>
          </Card>
          <Heading size={900} color="#101840" alignSelf="center" marginBottom={majorScale(3)} textAlign="center" width={majorScale(64)}>STEP 4: FREE 15 MINUTE MASTERCLASS</Heading>
          <Heading size={500} color="#101840" alignSelf="center" marginBottom={majorScale(4)} textAlign="center" width={majorScale(72)}>How I learnt mix engineering from novice to expert using core frameworks and the 3 things you need to do the same.</Heading>
          <Button appearance="primary" size="large" backgroundColor="#474D66" borderColor="#474D66" alignSelf="center" width={majorScale(16)}>WATCH NOW</Button>
        </Pane> */}
        <Pane display="flex" flexDirection="column" background="gray900" width="100vw" paddingX={majorScale(16)} paddingY={majorScale(8)}>
          <Paragraph color="#696F8C" textAlign="center">Melodicjeanious Academy © 2022, All rights reserved.</Paragraph>
        </Pane>
      </Device>
    </>
  )
}

import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import GA from './utils/analytics/GoogleAnalytics'
import FP from './utils/analytics/FacebookPixel'
import BookingConfirmed from './views/booking-confirmed'
import Home from './views/home'
function Booking () {
  return (
    <Router>
      <GA.GAComponent/>
      <FP.PixelComponent/>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/mixing-confirmed" element={<BookingConfirmed />} />
      </Routes>
    </Router>
  )
}

export default Booking

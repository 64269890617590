import { useEffect } from 'react'

class FacebookPixel {
  constructor () {
    this.initialized = false
    this.debug = false
    this.defaultOptions = {
      autoConfig: true,
      debug: false
    }
  }

  /**
     * Warns arguments on console, works when Pixel is not in debug mode
     * @param  {Object} args Arguments to log
     * @returns {undefined}
     */
  warn = (...args) => {
    if (!this.debug) { return }
    console.info(...['[Facebook-Pixel]'].concat(args))
  }

  /**
     * Log arguments on console, works when Pixel is not in debug mode
     * @param  {Object} args Arguments to log
     * @returns {undefined}
     */
  log = (...args) => {
    if (!this.debug) { return }
    console.info(...['[Facebook-Pixel]'].concat(args))
  }

  /**
     * Check if Pixel is initialized
     * @returns {Boolean} isInit
     */
  verifyInit = () => {
    if (!this.initialized) this.warn('Pixel not initialized before using call init() with required params')
    return this.initialized
  }

  /**
     *
     * @param {Number} pixelId Facebook Pixel ID
     * @param {Object} advancedMatching Add more customer info to pixel so you can track later
     * @param {Object} options Extra FB init options e.g autoConfig:boolean, debug:boolean.
     */
  init = (pixelId, advancedMatching = {}, options = this.defaultOptions) => {
    this.initialized = (typeof window !== 'undefined') && !!window.fbq
    /* eslint-disable */
        !function (f, b, e, v, n, t, s) {
            if (f.fbq) return; n = f.fbq = function () {
                n.callMethod ?
                    n.callMethod.apply(n, arguments) : n.queue.push(arguments)
            };
            if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
            n.queue = []; t = b.createElement(e); t.async = !0;
            t.src = v; s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s)
        }(window, document, 'script',
            'https://connect.facebook.net/en_US/fbevents.js');
        /* eslint-enable */
    if (!pixelId) {
      this.warn('Please insert pixel id for initializing')
    } else {
      if (options.autoConfig === false) {
        this.fbq('set', 'autoConfig', false, pixelId)
      }
      this.fbq('init', pixelId, advancedMatching)
      this.initialized = true
      this.debug = options.debug
    }
  }

  /**
     * Logs page view to FB, logs on console too if in debug mode
     * @returns {undefined}
     */
  logPageView = () => {
    if (!this.verifyInit()) {
      return
    }

    this.fbq('track', 'PageView')

    if (this.debug) {
      this.log("called fbq('track', 'PageView');")
    }
  }

  /**
     * Sends key-value data to FB, logs to console in debug mode
     * @param {String} title Title of data
     * @param {Object} data Data to send
     * @returns {undefined}
     */
  track = (title, data) => {
    if (!this.verifyInit()) {
      return
    }

    this.fbq('track', title, data)

    if (this.debug) {
      this.log(`called fbq('track', '${title}');`)

      if (data) {
        this.log('with data', data)
      }
    }
  }

  /**
     * Sends single data to FB for a specific pixel, logs to console in debug mode
     * @param {Number} pixel Facebook Pixel ID
     * @param {String} title Title of data
     * @param {Object} data Data to send
     * @returns {undefined}
     */
  trackSingle = (pixel, title, data) => {
    if (!this.verifyInit()) {
      return
    }

    this.fbq('trackSingle', pixel, title, data)

    if (this.debug) {
      this.log(`called fbq('trackSingle', '${pixel}', '${title}');`)

      if (data) {
        this.log('with data', data)
      }
    }
  }

  /**
     * Tracks event FB doesn't know about.
     * E.g People that sharedDiscount or Saved post. FB knows events like PageView.
     * Logs to console in debug mode
     * @param {String} event Event name
     * @param {Object} data Data to send
     * @returns {undefined}
     */
  trackCustom = (event, data) => {
    if (!this.verifyInit()) {
      return
    }

    this.fbq('trackCustom', event, data)

    if (this.debug) {
      this.log(`called fbq('trackCustom', '${event}');`)

      if (data) {
        this.log('with data', data)
      }
    }
  }

  /**
     * Tracks event FB doesn't know about for a specific pixel.
     * E.g People that sharedDiscount or Saved post. FB knows events like PageView.
     * Logs to console in debug mode
     * @param {Number} pixel Pixel ID
     * @param {String} event Event name
     * @param {Object} data Data to send
     * @returns {undefined}
     */
  trackSingleCustom = (pixel, event, data) => {
    if (!this.verifyInit()) {
      return
    }

    this.fbq('trackSingle', pixel, event, data)

    if (this.debug) {
      this.log(`called fbq('trackSingleCustom', '${pixel}', '${event}');`)

      if (data) {
        this.log('with data', data)
      }
    }
  }

  /**
     * Tell FB we have user consent to send pixel data.
     * This should come in after user accepts cookie consent
     * Logs to console in debug mode
     * @returns {undefined}
     */
  grantConsent = () => {
    if (!this.verifyInit()) {
      return
    }

    this.fbq('consent', 'grant')

    if (this.debug) {
      this.log('called fbq(\'consent\', \'grant\');')
    }
  }

  /**
     * Tell FB we don't user consent to send pixel data.
     * This should come in after user revokes cookie consent
     * Logs to console in debug mode
     * @returns {undefined}
     */
  revokeConsent = () => {
    if (!this.verifyInit()) {
      return
    }

    this.fbq('consent', 'revoke')

    if (this.debug) {
      this.log('called fbq(\'consent\', \'revoke\');')
    }
  }

  /**
     * Private method to reach out to FBQ.
     * Not recommended you call this function.
     * Logs to console in debug mode
     * @param  {...any} args
     * @returns {undefined}
     */
  fbq = (...args) => {
    if (!this.verifyInit()) {
      return
    }
    if (this.debug) {
      this.log(` - [FBQ Function] - called fbq('${args.slice(0, 2).join("', '")}')`)

      if (args[2]) {
        this.log('with data', args[2])
      }
    }
    window.window.fbq(...args)
    // eslint-disable-next-line consistent-return
    return window.window.fbq // For chaining
  }

  getFbQ = () => window.fbq || window.window.fbq || false
}
const Controller = new FacebookPixel()

const PixelComponent = () => {
  if (!window.melodicjeaniousUtils.pixel.options.debug) Controller.init(window.melodicjeaniousUtils.pixel.id, {}, window.melodicjeaniousUtils.pixel.options)
  // RUNS ONLY AFTER FIRST RENDER
  useEffect(() => {
    if (!window.melodicjeaniousUtils.pixel.options.debug) Controller.init(window.melodicjeaniousUtils.pixel.id, {}, window.melodicjeaniousUtils.pixel.options)
  }, [])
  // Runs on all re-renders
  useEffect(() => {
    Controller.logPageView()
  })
  return null
}

const fbPixel = {
  PixelComponent,
  Controller
}
export default fbPixel

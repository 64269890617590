import React from 'react'
import ReactDOM from 'react-dom/client'
import Booking from './booking'
import reportWebVitals from './reportWebVitals'
import './styles/index.scss'
const ENV_SANDBOX = 'sandbox'
const ENV_PRODUCTION = 'production'

window.env = !window.location.href.split('https://')[1] ? ENV_SANDBOX : ENV_PRODUCTION
window.melodicjeaniousUtils = {
  pixel: {
    id: '800175250839878',
    options: {
      autoConfig: true,
      debug: window.env === ENV_SANDBOX
    }
  },
  GA: {
    id: 'UA-163402106-1',
    options: {
      enable: window.env === ENV_PRODUCTION
    }
  }
}
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Booking />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
